/* eslint-disable */
import {request} from '../network';
import store from '@/store';

// 登录
export function login(data) {
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/auth/login',
    params: data
  })
}

// 获取登录验证码
export function loginCode(data) {
  return request({
    method: 'post',
    url: '/web/code/loginCode',
    params: data
  })
}

// 退出
export function logout(data) {
  return request({
    method: 'post',
    url: '/web/auth/loginOut',
    data
  })
}

// 通过旧密码找回密码
export function changePassOld(data) {
  return request({
    method: 'post',
    url: '/web/auth/changePassOld',
    data
  })
}

// 通过验证码找回密码
export function changePassCode(data) {
  return request({
    method: 'post',
    url: '/web/auth/changePassCode',
    data
  })
}

