/* eslint-disable */
import {request} from '../network';

// 通过邮箱验证码注册
export function registerEmailCode(data) {
  return request({
    method: 'post',
    url: '/web/code/registerEmailCode',
    params: data
  })
}
// 通过邮箱验证码找回密码（忘记密码）
export function passwordEmailCode(data) {
  return request({
    method: 'post',
    url: '/web/code/passwordEmailCode',
    params: data
  })
}
// 发送注册时候验证码
export function registerCode(data) {
  return request({
    method: 'post',
    url: '/web/code/registerCode',
    params: data
  })
}
export function passwordCode(data) {
  return request({
    method: 'post',
    url: '/web/code/passwordCode',
    params: data
  })
}

// 填写注册信息
export function setAccountInfo(data) {
  return request({
    method: 'post',
    url: '/web/register/setAccountInfo',
    data
  })
}

// 获取注册信息
export function getAccountInfo(data) {
  return request({
    method: 'post',
    url: '/web/register/getAccountInfo',
    data
  })
}
